<template>
  <modal-layout @close="$emit('close')" :title="$t('myGifts')">
    <div class="p-3">
      123
    </div>
  </modal-layout>
</template>

<script>
export default {
  name: "AgreementGiftModal",
}
</script>

<style lang="scss" scoped>

</style>