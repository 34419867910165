<template>
  <div class="agreement-card card redesign" :class="{'expiring': expirationComing && isExpirationColor}">
    <div class="card-head">
      <div class="icon-block">
        <icon icon-name="document-icon"></icon>
      </div>
      <div class="title-wrap-r">
        <h2 class="title">{{ agreement.insrTypeName }}</h2>
      </div>
    </div>
    <div class="card-body">
      <dl class="desc-list" v-if="agreement">
        <div class="agreement-wrap">

          <div>
            <dt class="desc-term">Дата создания подписки: </dt>
            <dd class="desc-detail">{{ dateGiven }}</dd>
          </div>
         <div v-if="isCanceledAndActive">
            <dt class="desc-term">Дата окончания подписки: </dt>
            <dd class="desc-detail" :class="{'error': expirationComing}">{{ changedDate }}</dd>
          </div>
          <!-- Номера машин-->
          <div v-if="['2225'].includes(agreement.insrType)">
            <dt class="desc-term">Номер машины:</dt>
            <dd class="desc-detail">
              {{ agreement.variableParams.CASKO_VEHICLE_NO }}
            </dd>
            <dt class="desc-term">Марка авто:</dt>
            <dd class="desc-detail">
              {{ agreement.variableParams.CASKO_MODEL_MAKE }}
            </dd>
          </div>
          <!-- Адрес -->
          <div v-if="['3014', '3016'].includes(agreement.insrType)">
            <dt class="desc-term">Адрес:</dt>
            <dd class="desc-detail">
              {{ agreement.variableParams.IMU_ADDRESS }}
            </dd>
          </div>
          <!-- Сумма -->
           <div v-if="agreement.insSum">
            <dt class="desc-term">Страховая сумма:</dt>
            <dd class="desc-detail" >
              {{agreement.insSum | priceFormat }} ₸
            </dd>
          </div>
        </div>
        <div class="link-wrap">
          <a
            @click="redirectTariff(agreement)"
            class="gray-btn"
            >{{$t('goTariff')}}
          </a>
        </div>
        <div class="d-flex" v-if="agreement.canEdit && false">
          <div v-if="agreement.insrType === '1000' && type === 'active' && [0, 11, 12].includes(agreement.state)">
            <a @click="changingEvent(agreement)" class="gray-btn">{{$t('changePolicy')}}</a>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import AgreementGiftModal from "./Modals/AgreementGiftModal";
import moment from "moment";
import eventLogger from '../services/eventLogger';
import {mapGetters, mapActions} from "vuex";

export default {
  name: "AgreementCardSubscription",
  props: {
    agreement: {
      type: Object
    },
    type: {
      type: String
    },
    isActive: {
      type: Boolean
    },
    isExpirationColor: {
      type: Boolean
    },
    policies: null
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgreementGiftModal
  },
  data() {
    return {
      currentPolicy: null,
    }
  },
  computed: {
    policiesTypes() {
      return this.policies.map(item => item.insurance_type);
    },
    changedDate() {
      return moment(this.agreement.dateNextWriteDowns).format("DD-MM-YYYY")
    },
    dateGiven() {
      return moment(this.agreement.dateGiven).format("DD-MM-YYYY")
    },
    expirationComing() {
      const endDate = new Date(this.agreement?.dateNextWriteDowns?.date);
      const today = new Date();
      const diffTime = endDate - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays <= 3;
    },
    isCanceledAndActive() {
      const endDate = new Date(this.agreement?.dateNextWriteDowns);
      const today = new Date();
      return (endDate - today)>0;
    }
  },
  methods: {
    changingEvent(agreement) {
      if(agreement.state == 0) {
        this.$modal.show('dialog', {
          title: 'Уважаемый клиент!',
          text:  'Переоформление можно сделать после 20:00 на следующий день после оформления',
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        });
        return;
      }
      eventLogger('select_change_policy', {policy_type: agreement.insrType})

      this.$router.push({
        name:'OgpoReinsurance', params:{id: agreement.id}
      })
    },
    async redirectTariff(agreement){
      let product_id;
      //Потом
      if(this.agreement.insrTypeName.indexOf('Каско') > -1){
        product_id = 12;
      }else if(this.agreement.insrTypeName.indexOf('вартир') > -1){
        product_id = 13;
      }else{
        product_id = 14;
      }
      //Потом

      let is_active = this.isActive ? 1 : 0;

      this.$router.push({
        name:'agreements.item', params:{id: product_id,agreement: agreement.policyId, active: is_active}
      })
    },

  },
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";

.agreement-card {
  padding: 16px;
  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .card-body {
    .desc-list {
      margin-bottom: 20px;

      .desc-term, .desc-detail {
        font-weight: 600;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        &.green {
          color: $primary-color;
        }
      }

      .desc-term {
        width: 55%;
        &.width-unset {
          width: unset;
          margin-right: 4px;
        }
      }
      ul {
        &.left {
          padding-left: 16px;
        }
        li {
          font-weight: 600;
          font-size: 12px;
          width: 75%;
          &.dot {
            list-style: disc;
          }
        }
      }
    }

    .gray-btn {
      margin-top: 10px;
      text-transform: uppercase;
      &.extend {
        color: #FCB016;
      }
    }

  }
}
.expiring {
  background: rgba(255, 0, 0, 0.05);
  border: 1px solid rgba(255, 0, 0, 0.6);
  .gray-btn {
    background-color: #fff;
  }
}


.agreement-card.redesign{
  .card-head{
    justify-content: initial;
    align-items: initial;
    margin-bottom: 16px;
    .title-wrap-r{
      padding-top: 6px;
      padding-bottom: 22px;
      border-bottom: 1px solid #C8C7CC;
      margin-left: 16px;
      width: 100%;
      .title{
        font-family: 'Manrope';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #071222;
        margin-bottom: 0px;
      }
    }
  }

  .card-body{
    .desc-list{
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;
      .agreement-wrap{
        width: 100%;
      }
      .desc-term{
        color: #99A3B3;
        font-weight: 500;
      }
      .desc-detail{
        color: #566681;
        font-weight: 700;
        &.error{
          color: #FF0000;
        }
      }
      .link-wrap .gray-btn{
        margin-top: 0px;
      }
    }
  }

}
</style>
