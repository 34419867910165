<template>
  <div class="agreement-card card redesign">
    <div class="card-head">
      <div class="icon-block">
        <icon icon-name="document-icon"></icon>
      </div>
      <div class="title-wrap-r">
        <h2 class="title" v-if="agreement.productName">  {{ agreement.productName }}</h2>
      </div>
    </div>
    <div class="card-body">
      <dl class="desc-list" v-if="agreement">
        <div class="agreement-wrap">
          
            <div>
              <dt class="desc-term">Дата создания:  </dt>
              <dd class="desc-detail">{{ dateGiven }}</dd>
            </div>
          <template v-if="agreement.nextShow">

              <div v-if="agreement.duration && agreement.durationUnit">
                <dt class="desc-term">Срок действия:  </dt>
                <dd class="desc-detail">{{agreement.duration}} {{ agreement.durationUnit }}</dd>
              </div>
               <div v-if="agreement.premKzt">
                <dt class="desc-term">Внесено: </dt>
                <dd class="desc-detail">{{agreement.premKzt}} ₸</dd>
              </div>
          </template>
        
           <div>
            <dt class="desc-term">Статус:  </dt>
            <dd class="desc-detail" v-if="agreement.nextShow">{{agreement.status}} </dd>
            <dd class="desc-detail" v-else>{{agreement.titleStatus}} </dd>
          </div>
        </div>
        <div class="link-wrap">
          <a
            @click="redirectTariff(agreement)"
            class="gray-btn"
            v-if="agreement.nextShow"
            >{{$t('goTariff')}}
          </a>
          <a
            class="gray-btn not-show"
            v-else
            >{{$t('goTariff')}}
          </a>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import AgreementGiftModal from "./Modals/AgreementGiftModal";
import moment from "moment";
import eventLogger from '../services/eventLogger';
import {mapGetters, mapActions} from "vuex";

export default {
  name: "AgreementCard",
  props: {
    agreement: {
      type: Object
    },
    type: {
      type: String
    },
    isActive: {
      type: Boolean
    },
    isExpirationColor: {
      type: Boolean
    },
    policies: null
  },
  components: {
    AgreementGiftModal
  },
  data() {
    return {
      currentPolicy: null,
    }
  },
  computed: {
    dateGiven() {
      return moment(this.agreement.startDate).format("DD-MM-YYYY")
    },
  },
  methods: {
    changingEvent(agreement) {
      if(agreement.state == 0) {
        this.$modal.show('dialog', {
          title: 'Уважаемый клиент!',
          text:  'Переоформление можно сделать после 20:00 на следующий день после оформления',
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        });
        return;
      }
      eventLogger('select_change_policy', {policy_type: agreement.insrType})

      this.$router.push({
        name:'OgpoReinsurance', params:{id: agreement.id}
      })
    },
    async redirectTariff(agreement){
      this.$router.push({
        name:'agreement.item.life', params:{id: agreement.policyId }
      });
    },

  },
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";

.agreement-card {
  padding: 16px;
  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .card-body {
    .desc-list {
      margin-bottom: 20px;

      .desc-term, .desc-detail {
        font-weight: 600;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        &.green {
          color: $primary-color;
        }
      }

      .desc-term {
        width: 55%;
        &.width-unset {
          width: unset;
          margin-right: 4px;
        }
      }
      ul {
        &.left {
          padding-left: 16px;
        }
        li {
          font-weight: 600;
          font-size: 12px;
          width: 75%;
          &.dot {
            list-style: disc;
          }
        }
      }
    }

    .gray-btn {
      margin-top: 10px;
      text-transform: uppercase;
      &.extend {
        color: #FCB016;
      }
    }

  }
}
.expiring {
  background: rgba(255, 0, 0, 0.05);
  border: 1px solid rgba(255, 0, 0, 0.6);
  .gray-btn {
    background-color: #fff;
  }
}


.agreement-card.redesign{
  .card-head{
    justify-content: initial;
    align-items: initial;
    margin-bottom: 16px;
    .title-wrap-r{
      padding-top: 6px;
      padding-bottom: 22px;
      border-bottom: 1px solid #C8C7CC;
      margin-left: 16px;
      width: 100%;
      .title{
        font-family: 'Manrope';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #071222;
        margin-bottom: 0px;
      }
    }
  }

  .card-body{
    .desc-list{
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;
      .agreement-wrap{
        width: 100%;
      }
      .desc-term{
        color: #99A3B3;
        font-weight: 500;
      }
      .desc-detail{
        color: #566681;
        font-weight: 700;
        &.error{
          color: #FF0000;
        }
      }
      .link-wrap .gray-btn{
        margin-top: 0px;
      }
    }
  }

}


.gray-btn.not-show{
  opacity: .2;
}
</style>
